.infosOperations {
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  margin: 15px 0;
  color: #9b9b9b;
}

.exitOperation > p {
  color: #171717;
}

.exitOperation > div > b {
  color: #ff0000;
}

.infosOperations > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.infosOperations > div > p {
  font-size: 14px;
  margin-top: 4px;
}

hr {
  border-color: #9b9b9b16;
}

.totalLending {
  color: #171717;
}
